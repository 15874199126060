// import Plyr from 'plyr';
import lightGallery from 'lightgallery';
// import { VidstackPlayer, VidstackPlayerLayout } from 'vidstack/global/player';
// import { VidstackPlayer, VidstackPlayerLayout } from 'vidstack/player'

import { mobileHandler } from './modules/mobile';
import { uiHandler } from './modules/ui';
import { formsHandler } from './modules/forms';
import { sharersHandler } from './modules/sharers';
import { slidersHandler } from './modules/sliders';

import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'

document.addEventListener('DOMContentLoaded', function (event) {
    mobileHandler.init();
    uiHandler.init();
    formsHandler.init();
    sharersHandler.init();
    slidersHandler.init();

    // const players = Array.from(document.querySelectorAll('.plyr-video')).map((p) => new Plyr(p));

    lightGallery(document.getElementById('lightgallery'), {
        plugins: [lgZoom, lgThumbnail],
        // licenseKey: 'your_license_key',
        speed: 500,
        // ... other settings
    });

    // const player = VidstackPlayer.create({
    //   target: '#target',
    //   title: 'Sprite Fight',
    //   src: 'https://files.vidstack.io/sprite-fight/720p.mp4',
    //   poster: 'https://files.vidstack.io/sprite-fight/poster.webp',
    //   layout: new VidstackPlayerLayout({
    //     thumbnails: 'https://files.vidstack.io/sprite-fight/thumbnails.vtt',
    //   }),
    // });
    
})



