import * as _utils from '../_utils';

const CURRENT_URL = encodeURI(window.location.href);
const SHARE_URL_FB = 'https://www.facebook.com/sharer/sharer.php?u=' + CURRENT_URL;
const SHARE_URL_TWITTER = 'https://twitter.com/intent/tweet?url=' + CURRENT_URL;
const SHARE_URL_LINKEDIN = 'https://www.linkedin.com/shareArticle?mini=true&' + CURRENT_URL;

export const sharersHandler = {

    init: function () {

        let $sharerItems = document.querySelectorAll('[data-sharer]');

        Array.prototype.forEach.call($sharerItems, ($sharer) => {
            $sharer.addEventListener('click', () => {
                let network = $sharer.dataset.sharer;
                sharersHandler.shareTo(network);
            });

        })

    },

    openPopup(url) {
        let popupW = 550;
        let popupH = 440;

        let offsetLeft = (_utils.getViewportWidth() - popupW) / 2;
        offsetLeft += window.screenX;

        let offsetTop = (_utils.getViewportHeight() - popupH) / 2;
        offsetTop += window.screenTop;

        window.open(url, '_blank', 'popup=yes, scrollbars=0, resizable=1, menubar=0, left=' + offsetLeft + ', top=' + offsetTop + ', width=' + popupW + ', height=' + popupH + ', toolbar=0, status=0');
    },

    shareTo(network) {
        switch (network) {
            case 'facebook':
                this.openPopup(SHARE_URL_FB);
                break;
            case 'twitter':
                this.openPopup(SHARE_URL_TWITTER);
                break;
            case 'linkedin':
                this.openPopup(SHARE_URL_LINKEDIN);
                break;
            default:
                break;
        }
    },

    // facebookShare() {
    //     this.openPopup(SHARE_URL_FB);
    // }

}