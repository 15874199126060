export const uiHandler = {

    init: () => {
        uiHandler.menu();
        uiHandler.stickyOffset();
        uiHandler.expandBlocks();
    },

    menu: () => {
        let $parentItems = document.querySelectorAll('.menu-has-items');

        if (!$parentItems) return;

        Array.prototype.forEach.call($parentItems, ($parentItem) => {
            $parentItem.addEventListener('click', () => {
                $parentItem.classList.toggle('uhu');
            });
        });

        document.addEventListener('click', (e) => {
            if (!e.target.matches('.menu-has-items')) {
                Array.prototype.forEach.call($parentItems, ($parentItem) => {
                    $parentItem.addEventListener('click', () => {
                        $parentItem.classList.remove('uhu');
                    });
                });
            }
        });
    },

    stickyOffset: () => {
        getOffset();

        window.addEventListener('resize', () => {
            getOffset();
        });

        function getOffset() {
            let $header = document.querySelector('#header-main');
            if (!$header) return;
            let headerHeight = $header.clientHeight;
            document.documentElement.style.setProperty('--sticky-offset', `${headerHeight}px`);
        }
    },

    expandBlocks: () => {

        let $triggers = document.querySelectorAll('button[data-expand-show]');
        if (!$triggers.length > 0) return;

        // Forces max-height:0 to all expand blocks
        let $expandBlocks = document.querySelectorAll('*[data-expand-id]');
        Array.prototype.forEach.call($expandBlocks, ($expandBlock) => {
            $expandBlock.classList.add('max-h-0');
        });

        Array.prototype.forEach.call($triggers, ($trigger) => {
            $trigger.addEventListener('click', () => {
                let blockID = $trigger.dataset.expandShow;
                document.querySelector('[data-expand-id="' + blockID + '"]').classList.toggle('max-h-full');
            });
        });

        // Close
        let $closeBlocks = document.querySelectorAll('button[data-expand-close]');
        Array.prototype.forEach.call($closeBlocks, ($closeBlock) => {
            $closeBlock.addEventListener('click', () => {
                let $block = $closeBlock.closest('*[data-expand-id]');
                $block.classList.remove('max-h-full');
            });
        });
    }

}