import * as _utils from '../_utils';


// core version + navigation, pagination modules:
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay, A11y } from 'swiper/modules';

Swiper.use([Navigation, Pagination, Autoplay, A11y]);

const $selectors = '.gslider';
const $selectorsMobile = '.gslider-mobile';
const mobileBreakpoint = _utils.getMobileBreakpoint();


export const slidersHandler = {

    init: function (element = document) {
        if (!element.querySelector($selectors) && !element.querySelector($selectorsMobile)) {
            return;
        }
        this.setSliders(element);
        // this.setMobileSliders(element);
    },

    setSliders(element) {

        let $sliders = element.querySelectorAll($selectors);

        if (!$sliders)
            return;

        /**
         * Set sliders
         */
        Array.prototype.forEach.call($sliders, ($slider, index) => {

            $slider.dataset.slider = 'slider-' + index;

            /** options **/
            // let $_spaceBetween = $slider.dataset.swiperSpaceBetween ? parseInt($slider.dataset.swiperSpaceBetween) : 20;
            // let $_autoHeight = $slider.dataset.swiperAutoHeight ? true : false;
            // let $_pagination = $slider.dataset.swiperPagination ? true : false;
            let $_autoplay = ($slider.dataset.swiperAutoplay && parseInt($slider.dataset.swiperAutoplay) > 0) ? parseInt($slider.dataset.swiperAutoplay) : null;
            // let $_loop = $slider.dataset.swiperLoop ? true : false;
            // let $_centeredSlides = $slider.dataset.swiperCenteredSlides ? true : false;
            // let $_slidesPerView = ($slider.dataset.swiperSlidesPerView && parseInt($slider.dataset.swiperSlidesPerView) > 0) ? parseInt($slider.dataset.swiperSlidesPerView) : 'auto';
            // let $_gridRows = ($slider.dataset.swiperGridRows && parseInt($slider.dataset.swiperGridRows) > 0) ? true : null;

            // console.log($_gridRows)
            // console.log($slider.dataset.yo, $slider.dataset.swiperGridRowsss, parseInt($slider.dataset.swiperGridRows), $_gridRows);
            let swiperOptions = {
                // slidesPerView: $_slidesPerView,
                // centeredSlides: $_centeredSlides,
                // centeredSlidesBounds: $_centeredSlides,
                // spaceBetween: $_spaceBetween,
                // autoHeight: $_autoHeight,

                // resistance: false,
                // resistanceRatio: 0,
                // threshold: 10,
                // preloadImages: true,
                // updateOnImagesReady: true,
                // a11y: {
                //     prevSlideMessage: 'Previous slide',
                //     nextSlideMessage: 'Next slide',
                // },
                // navigation: {
                //     nextEl: '[data-slider=' + $slider.dataset.slider + '] .btn-next',
                //     prevEl: '[data-slider=' + $slider.dataset.slider + '] .btn-prev',
                // },
                // on: {
                //     beforeInit: (swiper) => {
                //         // setLoop(swiper);
                //     },
                //     init: (swiper) => {
                //         $_gridRows ? setGridSlider(swiper) : '';
                //         // Fix Timeline height
                //         let isSliderTimeline = swiper.el.dataset.sliderType === 'timeline';
                //         if (isSliderTimeline) {
                //             setTimelineSlider(swiper)
                //         }
                        
                //         let isSliderAutoplay = swiper.el.dataset.swiperLoop === 'true';
                //         if(isSliderAutoplay){
                //             setLoop(swiper, 'init');
                //         }
                //     },
                //     resize: (swiper) => {
                //         $_gridRows ? setGridSlider(swiper) : '';
                        
                //         // Fix Timeline height
                //         let isSliderTimeline = swiper.el.dataset.sliderType === 'timeline';
                //         if (isSliderTimeline) {
                //             setTimelineSlider(swiper)
                //         }
                        
                //         // Custom Loop
                //         let isSliderAutoplay = swiper.el.dataset.swiperLoop === 'true';
                //         if(isSliderAutoplay){
                //             setLoop(swiper, 'resize');
                //         }
                //     },
                //     imagesReady: (swiper) => {
                //         $_gridRows ? setGridSlider(swiper) : '';
                //         // Fix Timeline height
                //         let isSliderTimeline = swiper.el.dataset.sliderType === 'timeline';
                //         if (isSliderTimeline) {
                //             setTimelineSlider(swiper)
                //         }
                //     },
                // },
            };

            // if ($_pagination) {
            //     swiperOptions['pagination'] = JSON.parse('{"el": ".krs-slider-pagination", "type": "bullets", "clickable": true, "bulletClass": "bullet", "bulletActiveClass": "current"}');
            // }

            // if ($_loop) {
            //     swiperOptions['loop'] = $_loop;
            //     // swiperOptions['centeredSlides'] = true;
            //     // swiperOptions['loop'] = false;
            //     // swiperOptions['breakpoints'] = JSON.parse('{"1024":{"loop": "true"}}');
            // }
            
            if ($_autoplay) {
                swiperOptions['autoplay'] = JSON.parse('{"delay": '+$_autoplay+', "disableOnInteraction": false, "pauseOnMouseEnter": true}');;
            }

            // if ($_gridRows) {
            //     swiperOptions['grid'] = JSON.parse('{ "fill": "row", "rows": ' + parseInt($slider.dataset.swiperGridRows) + '}');
            // }
            let slider = new Swiper('[data-slider=' + $slider.dataset.slider + ']', swiperOptions);

            /** 'Show Label' option **/
            // let $showLabel = $slider.dataset.showLabel;
            // if (typeof $showLabel !== 'undefined' && $showLabel == 'true') {
            //     let placeholder = $slider.querySelector('.krs-slider-nav .krs-slider-label');
            //     slider.on('slideChange', () => {
            //         let activeIndex = slider.activeIndex;
            //         let label = slider.slides[activeIndex].querySelector('.block-carrousel__keypoint').innerHTML;
            //         placeholder.innerHTML = label;
            //     });
            // }
        });

        /** RealWidth helper (for loop mode) */
        function setLoop(swiper,handler){
            swiper.autoplay.pause();

            let $slides = swiper.slides;
            let realWidth = 0;
            let $slidesDuplicates = $slides.filter(node => node.classList.contains("swiper-slide-duplicate"))
            $slides = $slides.filter(node => !node.classList.contains("swiper-slide-duplicate"))
            Array.prototype.forEach.call($slides, ($slide) => {
                realWidth += $slide.offsetWidth;
            })
            // console.log(handler + ' ', realWidth,swiper.width, window.innerWidth, realWidth<swiper.width);
            
            if(realWidth<swiper.width){
                swiper.el.classList.add('slider-centered');
                Array.prototype.forEach.call($slidesDuplicates, ($slideDuplicate) => {
                    $slideDuplicate.style.display = 'none';
                });
            } else {
                swiper.autoplay.run();
                swiper.el.classList.remove('slider-centered');
                Array.prototype.forEach.call($slidesDuplicates, ($slideDuplicate) => {
                    $slideDuplicate.style.display = '';
                });
            }
        }

        function setGridSlider(swiper) {
            let arrayHeight = [];
            let slides = swiper.slides;
            let cols = swiper.el.querySelectorAll('.block-catalog.head').length;
            // console.log(slides, cols);
            Array.prototype.forEach.call(slides, (slide, index) => {
                // console.log(slide, slide.clientHeight);
                arrayHeight[index] = slide.clientHeight;
            });

            let chunked = sliceIntoChunks(arrayHeight, cols);
            // console.log(arrayHeight, chunked);

            let $rowHeads = swiper.el.querySelectorAll('.slider-catalogue-rowHead>*');
            Array.prototype.forEach.call($rowHeads, ($rowHead, index) => {
                let height = Math.max(...chunked[index]);
                $rowHead.style.height = height + 'px';
            });
        }

        function setTimelineSlider(swiper) {
            // Fix Timeline height
            let isSliderTimeline = swiper.el.dataset.sliderType === 'timeline';
            if (!isSliderTimeline) {
                return;
            }
            let slidesHeights = [];
            let $slidesTimeline = swiper.el.querySelectorAll('.block-timeline');

            Array.prototype.forEach.call($slidesTimeline, ($slide, i) => {
                let cardHeight = $slide.querySelector('.timeline-symbol').clientHeight * 0.5 + $slide.querySelector('.timeline-content').clientHeight;
                slidesHeights.push(cardHeight);
            });

            let cardHeightMax = Math.max(...slidesHeights);
            Array.prototype.forEach.call($slidesTimeline, ($slide, i) => {
                $slide.parentNode.style.minHeight = cardHeightMax * 2 + 'px';
            });
        }

        function sliceIntoChunks(arr, chunkSize) {
            const res = [];
            for (let i = 0; i < arr.length; i += chunkSize) {
                const chunk = arr.slice(i, i + chunkSize);
                res.push(chunk);
            }
            return res;
        }

    },

    setMobileSliders(element) {
        // let $slidersMobile = element.querySelectorAll($selectorsMobile);

        // Array.prototype.forEach.call($slidersMobile, ($slider, index) => {
        //     $slider.dataset.mobileSlider = 'mobile-slider-' + index;
        //     let sliderBreakpoint = $slider.dataset.mobileBreakpoint;
        //     // alert('yoo')
        //     let a11yMobileSlider;
        //     let swiperOptions = {
        //         slidesPerView: 'auto',
        //         // spaceBetween: 50,
        //         a11y: {
        //             prevSlideMessage: 'Previous slide',
        //             nextSlideMessage: 'Next slide',
        //         },
        //         navigation: {
        //             nextEl: '[data-mobile-slider=' + $slider.dataset.mobileSlider + '] .btn-next',
        //             prevEl: '[data-mobile-slider=' + $slider.dataset.mobileSlider + '] .btn-prev',
        //         },
        //         autoplay: {
        //             // delay: 3000,
        //         },
        //     };
        //     if (_utils.getViewportWidth() <= sliderBreakpoint) {
        //         // $('[data-mobile-slider=' + $slider.dataset.slider +']').slick();
        //         a11yMobileSlider = new Swiper('[data-mobile-slider=' + $slider.dataset.mobileSlider + ']', swiperOptions);
        //         // console.log(a11yMobileSlider)
        //         // a11yMobileSlider = new slider(document.querySelector('[data-mobile-slider=' + $slider.dataset.mobileSlider +']'), {
        //         //     // autoplay: true,
        //         //     slidesToShow: 2,
        //         //     // autoplaySpeed: 2500
        //         // });

        //         // } else {
        //         //     console.log('destroy')
        //         //     a11yMobileSlider ? a11yMobileSlider.destroy() : '';
        //     }
        //     window.addEventListener("resize", function () {
        //         a11yMobileSlider ? a11yMobileSlider.destroy() : '';
        //         if (_utils.getViewportWidth() <= sliderBreakpoint) {
        //             a11yMobileSlider = new Swiper('[data-mobile-slider=' + $slider.dataset.mobileSlider + ']', swiperOptions);
        //             //         // console.log('shoot')
        //             //         a11yMobileSlider = new slider(document.querySelector('[data-mobile-slider=' + $slider.dataset.mobileSlider +']'), {
        //             //             // autoplay: true,
        //             //             slidesToShow: 2,
        //             //             // autoplaySpeed: 2500
        //             //         });
        //             // } else {
        //             //     //     console.log('destroy')
        //             //     //     a11yMobileSlider ? a11yMobileSlider.destroy() : '';
        //         }
        //     });
        // });

    }

};

// window.$ = window.jQuery = jQuery;
if (window.acf) {
    // slidersHandler.init();
    window.acf.addAction('render_block_preview', function (response, $el, data) {
        if (response.length > 0) {
            // let element = response[0];
            slidersHandler.init();
        }
    });
}

document.addEventListener('DOMContentLoaded', function (event) {
    slidersHandler.init();
});